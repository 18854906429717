export default [
  {
    header: 'Warehouse',
    icon: 'PackageIcon',
    i18n: 'Warehouse',
    items: [
      {
        url: '/warehouse/products',
        name: 'Products',
        slug: 'products',
        icon: 'StarIcon',
        i18n: 'Products'
      },
      {
        url: '/warehouse/brands',
        name: 'Brands',
        slug: 'brands',
        icon: 'TagIcon',
        i18n: 'Brands'
      },
      {
        url: '/warehouse/categories',
        name: 'Categories',
        slug: 'categories',
        icon: 'ShoppingBagIcon',
        i18n: 'Categories'
      },
      {
        url: '/warehouse/warehouses',
        name: 'Warehouses',
        slug: 'warehouses',
        icon: 'BoxIcon',
        i18n: 'Warehouses'
      },
      {
        url: '/warehouse/vehicles',
        name: 'Vehicles',
        slug: 'vehicles',
        icon: 'TruckIcon',
        i18n: 'Vehicles'
      },
      {
        url: '/warehouse/merchants',
        name: 'Merchants',
        slug: 'merchants',
        icon: 'UserIcon',
        i18n: 'Merchants'
      }
    ]
  },
  {
    header: 'Movements',
    icon: 'TruckIcon',
    i18n: 'Movements',
    items: [
      {
        url: '/warehouse/inputs',
        name: 'Inputs',
        slug: 'inputs',
        icon: 'PlusIcon',
        i18n: 'Inputs'
      },
      {
        url: '/warehouse/outputs',
        name: 'Outputs',
        slug: 'outputs',
        icon: 'MinusIcon',
        i18n: 'Outputs'
      },
      {
        url: '/warehouse/transfers',
        name: 'Transfers',
        slug: 'transfers',
        icon: 'Maximize2Icon',
        i18n: 'Transfers'
      },
      {
        url: '/warehouse/movements',
        name: 'Movements',
        slug: 'movements',
        icon: 'EyeIcon',
        i18n: 'Movements'
      },
      {
        url: '/warehouse/requests',
        name: 'Requests',
        slug: 'requests',
        icon: 'BellIcon',
        i18n: 'Requests'
      }
    ]
  },
  {
    header: 'Purchases',
    icon: 'PackageIcon',
    i18n: 'Purchases',
    items: [
      {
        url: '/purchases/providers',
        name: 'Providers',
        slug: 'providers',
        icon: 'ListIcon',
        i18n: 'Providers'
      },
      {
        url: '/purchases/invoices',
        name: 'Invoices',
        slug: 'invoices',
        icon: 'DollarSignIcon',
        i18n: 'Invoices'
      },
      {
        url: '/warehouse/orders',
        name: 'Orders',
        slug: 'orders',
        icon: 'ShieldIcon',
        i18n: 'Orders'
      }
    ]
  },
  {
    header: 'Sales',
    icon: 'PackageIcon',
    i18n: 'Sales',
    items: [
      {
        url: '/sales/clients',
        name: 'Clients',
        slug: 'clients',
        icon: 'ListIcon',
        i18n: 'Clients'
      },
      {
        url: '/sales/sales',
        name: 'Sales',
        slug: 'sales',
        icon: 'DollarSignIcon',
        i18n: 'Invoices'
      }
    ]
  },
  {
    header: 'Reports',
    icon: 'PackageIcon',
    i18n: 'Reports',
    items: [
      {
        url: '/reports/settlements',
        name: 'Settlements',
        slug: 'settlements',
        icon: 'DollarSignIcon',
        i18n: 'Settlements'
      }
    ]
  }
]

